<template>
  <v-container class="fill-height" :fluid="true">
    <v-row align="center" justify="center">
      <v-spacer />
      <div>
        <h1 class="display-1 font-weight-thin mb-4">404</h1>
        <h2 class="display-4 font-weight-thin mb-4">
          <Translation item="page_not_found" />
        </h2>
        <p class="subheading mb-4">
          <Translation item="page_not_found_description" />
        </p>
        <CustomLink to="/">
          <v-btn color="primary">
            <Translation item="page_not_found_button" />
          </v-btn>
        </CustomLink>
      </div>
      <v-spacer />
    </v-row>
  </v-container>
</template>
